//General Stylesheet
// Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

//Definition of theme colors for bootstrap according he JRC color guide
$gray: #E5E7EB;
$body-bg: $gray;
$font-family-sans-serif: 'Lato', sans-serif;
$enable-responsive-font-sizes: true;
$primary: #033060;
$secondary: #109ac4;
$success: #75b678;
$warning: #E8DDB5 !global;
$dark: darken($primary, 5%) !global;

/* import bootstrap to set changes */
@import "bootstrap/scss/bootstrap.scss";
@import 'bootstrap-icons/font/bootstrap-icons.css';

body{
    font-family: 'Lato', sans-serif;
}

.body_content{
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.bc{
    margin-left: 20px;
}

.breadcrumbs {
    display: flex;
    background-color: '#109ac4';
    justify-content: left;   
    color:#ffffff;
    margin-right: 30px;
    font-size: 17px ;
  
   
    
  }
  
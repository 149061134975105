.tabContainer{
    margin:20px;
  

}

.coloredTab {
    font-size: 20px;
    font-weight:400;
    
   
  }
  

  .tabContent{
    background-color: #ffffff;
  }

  .content_title{

    padding: 1rem;
    font-size: 20px;
  }

  .accordion_block{
    margin:1rem;
  }

  .accordion_header{
    font-size: 24px;
    font-weight: bold;
  }

  .accordion_content{
    padding: 1rem;
    font-size:18px;
  }

  .formula{
    font-size:18px;
    font-weight:bold;
    margin:20px;
  }

  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: orangered;
    background-color: #282c34;
    border-color: none;
}

.nav-tabs {
    border-bottom: none;
    background-color: #61dafb;
    
}

.nav-tabs .nav-link:hover{
    color: gray
}
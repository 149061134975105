@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    opacity: 0.9;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-container{

    
    margin: 0;

    position: absolute;
    top: 50%;
    left:50%;
    z-index: 1; /* Sit on top */
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    overflow: auto; /* Enable scroll if needed */

  }

  .loading-legend{
    text-align: center;
  }


.background-login {
 background-color: #01021B;
 height: 100vh;
 min-width: 100%;
} 


.logo-login{
    width: 110px;
    height: 40px;
    margin: 15px 15px 10px 20px;

}

.content{
    height: 100%;
    display: flex;
    color:white;
    margin-left: 15px;
    margin-right: 15px;
}

.signing{
    width: 100%;
}
.forgot_password{

    text-align: center;
    color:white;
    padding-top: 40px;   
    
}

a{
    color: white;
}

.imageMap{
    max-width: 50%; overflow: hidden;
    background-color:#01021B;
    text-indent: -1900px;
}

.imageMap img{
    max-height: 100vh; 
    max-width: initial; }
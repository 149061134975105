.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }
  
  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

@keyframes color_change {
  from { background-color: blue; }
  to { background-color: red; }
}

@keyframes fade {
  from { opacity: 0.5; }
}
.blinking {

  animation-name: color_change , fade;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  border: 5px solid;
  border-image: linear-gradient(45deg,red,blue) 10;

}
.geeks {
  width: 400px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.geeks::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: blue;
  animation: animate 5s  infinite;
}

@keyframes animate {
  0% {
    left: 0;
  }

  50% {
    left: 100%;
  }

  0% {
    left: 0;
  }
}

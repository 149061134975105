@import '../index.scss';
.basico{
    font-weight: 100;
}

.nav-bar{
    max-height: 70px;
    width: 100%;
    background-color: $dark;
    color: white;
 
}
.logo_menu{
    max-width:100px;
    margin-left: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
}



.options{
    color:white;
}
.nav-link{
    font-family: 'Roboto', sans-serif;
    color: white!important;
   
  }




